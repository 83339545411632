<template>
  <div>
    <div class="card card-custom">
      <div
        class="card-header d-flex align-items-center justify-content-between"
      >
        <div class="card-title">
          <h3 class="card-label">Yangi postavshik ro'yxatga olish</h3>
        </div>
      </div>
      <div class="card-body">
        <form>
          <div>
            <div>
              <v-select
                :items="clientType"
                v-model="selected_opt"
                item-text="name"
                dense
                item-value="value"
                label="Mijoz turi"
                outlined
              ></v-select>
            </div>
            <div class="d-flex">
              <div class="col-4 pl-0">
                <v-text-field
                  :error-messages="nameErrors"
                  v-model="username"
                  label="Username"
                  outlined
                  required
                  dense
                  @input="$v.username.$touch()"
                  @blur="$v.username.$touch()"
                ></v-text-field>
              </div>
              <div class="col-4">
                <v-text-field
                  type="password"
                  v-model="password"
                  label="Password"
                  :error-messages="passErrors"
                  outlined
                  dense
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  required
                ></v-text-field>
              </div>

              <div class="col-4 pr-0">
                <v-text-field
                  type="password"
                  v-model="repassword"
                  :error-messages="rePassErrors"
                  label="Parolni tasdiqlang"
                  outlined
                  required
                  dense
                  @input="$v.repassword.$touch()"
                  @blur="$v.repassword.$touch()"
                ></v-text-field>
              </div>
            </div>

            <div
              v-if="jis"
              style="border: 1px solid #333; padding: 20px; border-radius: 4px"
            >
              <h4 class="my-5 text-dark">Jismoniy shaxs malumotlari</h4>
              <div class="d-flex">
                <div class="col-4 pl-0">
                  <v-text-field
                    :error-messages="clientNameErrors"
                    v-model="clientName"
                    label="Mijoz nomi"
                    outlined
                    required
                    dense
                    @input="$v.clientName.$touch()"
                    @blur="$v.clientName.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-4">
                  <v-text-field
                    v-model="emailaddress"
                    outlined
                    dense
                    label="Manzil"
                    required
                  ></v-text-field>
                </div>

                <div class="col-4 pr-0">
                  <v-text-field
                    dense
                    v-mask="'AA#######'"
                    placeholder="(AA)1234567"
                    v-model="passportNum"
                    label="Pasport raqami"
                    outlined
                  ></v-text-field>
                </div>
              </div>

              <div class="d-flex">
                <div class="col-4 pl-0">
                  <v-combobox
                    v-model="oblast"
                    :error-messages="oblastErrors"
                    :items="getallregions"
                    item-text="name"
                    item-value="id"
                    label="Oblast"
                    outlined
                    required
                    dense
                    @input="$v.oblast.$touch()"
                    @blur="$v.oblast.$touch()"
                  ></v-combobox>
                </div>
                <div class="col-4">
                  <v-combobox
                    v-model="region"
                    :error-messages="regionErrors"
                    :items="returnRegions"
                    item-text="name"
                    item-value="id"
                    label="Region"
                    outlined
                    dense
                    @input="$v.region.$touch()"
                    @blur="$v.region.$touch()"
                  ></v-combobox>
                </div>
                <div class="col-4 pr-0">
                  <v-select
                    :error-messages="StatusErrors"
                    :items="allStatusName"
                    v-model="status"
                    item-text="status"
                    dense
                    item-value="id"
                    label="Status"
                    outlined
                    @input="$v.status.$touch()"
                    @blur="$v.status.$touch()"
                  ></v-select>
                </div>
              </div>

              <div class="col-12 px-0">
                <v-textarea
                  outlined
                  dense
                  v-model="comments"
                  label="Izoh"
                ></v-textarea>
              </div>
            </div>
          </div>

          <div v-if="yur">
            <div
              class="my-5"
              style="border: 1px solid #333; padding: 20px; border-radius: 4px"
            >
              <h4 class="my-5 text-dark">Yuridik shaxs malumotlari</h4>

              <div class="d-flex">
                <div class="col-3 pl-0">
                  <v-text-field
                    v-model="clientName"
                    label="Mijoz nomi"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    outlined
                    dense
                    v-model="innNum"
                    v-mask="'### ### ###'"
                    label="INN"
                    required
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    v-model="okedNum"
                    :counter="5"
                    v-mask="'#####'"
                    label="OKED"
                    dense
                    required
                    outlined
                  ></v-text-field>
                </div>

                <div class="col-3 pr-0">
                  <v-text-field
                    outlined
                    v-model="ndsNum"
                    :counter="15"
                    dense
                    label="NDS raqami"
                    v-mask="'###############'"
                    required
                  ></v-text-field>
                </div>
              </div>

              <div class="d-flex">
                <div class="col-3 pl-0">
                  <v-text-field
                    v-model="emailaddress"
                    outlined
                    dense
                    label="Pochta manzili"
                    required
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-text-field
                    dense
                    outlined
                    v-model="yurAddress"
                    label="Yuridik manzil"
                    required
                  ></v-text-field>
                </div>

                <div class="col-3">
                  <v-combobox
                    v-model="oblast"
                    :error-messages="oblastErrors"
                    :items="getallregions"
                    item-text="name"
                    item-value="id"
                    label="Oblast"
                    outlined
                    required
                    @input="$v.oblast.$touch()"
                    @blur="$v.oblast.$touch()"
                    dense
                  ></v-combobox>
                </div>

                <div class="col-3">
                  <v-combobox
                    v-model="region"
                    :items="returnRegions"
                    item-text="name"
                    :error-messages="regionErrors"
                    item-value="id"
                    label="Region"
                    outlined
                    @input="$v.region.$touch()"
                    @blur="$v.region.$touch()"
                    dense
                  ></v-combobox>
                </div>
              </div>

              <h4 class="my-5 text-dark">Bank malumotlari:</h4>

              <div class="d-flex">
                <div class="col-6 pl-0">
                  <v-autocomplete
                    v-model="mfo"
                    :items="getMfo"
                    dense
                    item-text="full_name"
                    item-value="id"
                    outlined
                    label="Mfo"
                    @keypress="isNumberKey"
                    v-debounce:200ms="getMfoDebounce"
                  ></v-autocomplete>
                </div>
                <div class="col-6 pr-0">
                  <v-text-field
                    v-model="account"
                    outlined
                    dense
                    label="Bank hisob raqami"
                    v-mask="'#### #### #### #### ####'"
                    :error-messages="bankNumber"
                    @input="$v.account.$touch()"
                    @blur="$v.account.$touch()"
                  ></v-text-field>
                </div>
              </div>

              <div class="d-flex">
                <div class="col-6 pl-0">
                  <v-select
                    :items="returnOwnership"
                    v-model="ownership"
                    item-text="name"
                    item-value="id"
                    dense
                    label="Mulchilik shakli"
                    outlined
                  ></v-select>
                </div>
                <div class="col-6 pr-0">
                  <v-select
                    :error-messages="StatusErrors"
                    :items="allStatusName"
                    v-model="status"
                    dense
                    item-text="status"
                    item-value="id"
                    label="Status"
                    outlined
                    @input="$v.status.$touch()"
                    @blur="$v.status.$touch()"
                  ></v-select>
                </div>
              </div>
              <div>
                <v-textarea
                  outlined
                  dense
                  v-model="comments"
                  label="Comments"
                ></v-textarea>
              </div>
            </div>

            <div
              style="border: 1px solid #333; padding: 20px; border-radius: 4px"
            >
              <h4 class="mb-5 text-dark">Kontakt malumotlari</h4>

              <h4 class="mb-5 text-dark">Direktor:</h4>
              <div>
                <v-text-field
                  v-model="directorFIO"
                  label="FIO"
                  outlined
                  dense
                  :error-messages="dirNameErrors"
                  required
                  @input="$v.directorFIO.$touch()"
                  @blur="$v.directorFIO.$touch()"
                ></v-text-field>
              </div>

              <label>Telefon raqam</label>
              <div class="d-flex">
                <div class="col-6 pl-0">
                  <v-text-field
                    v-model="directorPhoneNumber1"
                    outlined
                    dense
                    placeholder="(XX) XXX XX XX"
                    v-mask="'(##) ### ## ##'"
                  ></v-text-field>
                </div>
                <div class="col-6 pr-0">
                  <v-text-field
                    v-model="directorPhoneNumber2"
                    outlined
                    dense
                    placeholder="(XX) XXX XX XX"
                    v-mask="'(##) ### ## ##'"
                  ></v-text-field>
                </div>
              </div>

              <h4 class="mb-5 text-dark">Hodim:</h4>
              <div class="d-flex">
                <div class="col-6 pl-0">
                  <v-text-field
                    v-model="staffName"
                    outlined
                    dense
                    label="FIO"
                  ></v-text-field>
                </div>

                <div class="col-6 pr-0">
                  <v-select
                    :items="returnPositions"
                    v-model="staffposition"
                    item-text="name"
                    item-value="id"
                    dense
                    label="Lavozim"
                    outlined
                  ></v-select>
                </div>
              </div>

              <label>Telefon raqam</label>
              <div class="d-flex">
                <div class="col-6 pl-0">
                  <v-text-field
                    v-model="staffPhoneNumber1"
                    placeholder="(XX) XXX XX XX"
                    outlined
                    dense
                    v-mask="'(##) ### ## ##'"
                  ></v-text-field>
                </div>

                <div class="col-6 pr-0">
                  <v-text-field
                    v-model="staffPhoneNumber2"
                    placeholder="(XX) XXX XX XX"
                    outlined
                    dense
                    v-mask="'(##) ### ## ##'"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between border-top pt-10">
            <div>
              <v-btn
                color="success"
                :disabled="newProviderLoading"
                @click.prevent="submit"
              >
                <i v-if="newProviderLoading" class="el-icon-loading"></i>
                Yuborish
              </v-btn>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import { isNumberKey } from '@/utils'
export default {
  data() {
    return {
      newProviderLoading: false,
      code: '998',
      directorPhoneNumber1: '',
      directorPhoneNumber2: '',
      staffPhoneNumber1: '',
      staffPhoneNumber2: '',
      clientType: [
        { name: 'Jismoniy shaxs', value: 'JI' },
        { name: 'Yuridik shaxs', value: 'YU' }
      ],
      username: '',
      clientName: '',
      innNum: '',
      emailaddress: '',
      password: '',
      repassword: '',
      passportNum: '',
      comments: '',
      okedNum: '',
      oblast: '',
      region: '',
      yurAddress: '',
      ndsNum: '',
      mfo: '',
      status: '',
      account: '',
      staffName: '',
      mail: '',
      selected_opt: '',
      jis: false,
      yur: false,
      staffposition: '',
      directorPhoneNumber: '',
      staffPhoneNumber: '',
      directorPhoneNumberList: [],
      staffPhoneNumberList: [],
      staffDeatailLists: [],
      provider_bank_account: [],
      ownership: '',
      directorFIO: ''
    }
  },
  created() {
    this.$store.dispatch('staffPositions')
    this.$store.dispatch('getPositions')
  },
  validations() {
    if (this.jis == true) {
      return {
        username: {
          required
        },
        oblast: {
          required
        },
        status: {
          required
        },
        password: {
          required,
          minLength: minLength(8)
        },
        repassword: {
          sameAsPassword: sameAs('password')
        },
        region: {
          required
        },
        clientName: {
          required
        }
      }
    } else {
      return {
        account: {
          required,
          minLength: minLength(24)
        },
        username: {
          required
        },
        status: {
          required
        },
        oblast: {
          required
        },
        password: {
          required,
          minLength: minLength(8)
        },
        repassword: {
          sameAsPassword: sameAs('password')
        },
        region: {
          required
        },
        directorFIO: {
          required
        },
        clientName: {
          required
        }
        // directorPhoneNumber: {
        //   required
        // }
      }
    }
  },
  watch: {
    selected_opt: function (value) {
      if (value === 'JI') {
        this.yur = false
        this.jis = true
      }
      if (value === 'YU') {
        this.jis = false
        this.yur = true
      }
    },
    username: function (val) {
      this.username = val.toLowerCase()
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(this.username))
        this.username = this.username.replace(/\s/g, '')
    },
    clientName(val) {
      const str = val.replace(
        new RegExp('(?:\\b|_)([a-z])', 'g'),
        function ($1) {
          return $1.toUpperCase()
        }
      )
      this.clientName = str
    },
    staffName(val) {
      const str = val.replace(
        new RegExp('(?:\\b|_)([a-z])', 'g'),
        function ($1) {
          return $1.toUpperCase()
        }
      )
      this.staffName = str
    },
    directorFIO(val) {
      const str = val.replace(
        new RegExp('(?:\\b|_)([a-z])', 'g'),
        function ($1) {
          return $1.toUpperCase()
        }
      )
      this.directorFIO = str
    }
  },
  beforeCreate() {
    this.$store.dispatch('getOwnership')
  },
  computed: {
    getMfo() {
      return this.$store.state.requests.allMfoBank
    },
    returnPositions() {
      return this.$store.state.requests.positions.results
    },
    returnPositions2() {
      return this.$store.state.requests.staffPositions
    },
    // dirPhoneErrors() {
    //   const errors = [];
    //   if (!this.$v.directorPhoneNumber.$dirty) return errors;
    //   !this.$v.directorPhoneNumber.required &&
    //     errors.push("To'ldirilishi shart bo'lgan qator");
    //   return errors;
    // },
    bankNumber() {
      const errors = []
      if (!this.$v.account.$dirty) return errors
      !this.$v.account.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")

      if (
        !this.$v.account.minLength ||
        this.account.replace(/( )/g, ' ').length < 20
      ) {
        errors.push("Bank hisob raqami 20 ta raqamdam kam bo'lmasligi kerak")
      }

      return errors
    },
    dirNameErrors() {
      const errors = []
      if (!this.$v.directorFIO.$dirty) return errors
      !this.$v.directorFIO.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    StatusErrors() {
      const errors = []
      if (!this.$v.status.$dirty) return errors
      !this.$v.status.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    oblastErrors() {
      const errors = []
      if (!this.$v.oblast.$dirty) return errors
      !this.$v.oblast.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    rePassErrors() {
      const errors = []
      if (!this.$v.repassword.$dirty) return errors
      !this.$v.repassword.sameAsPassword &&
        errors.push('Parol most bolishi kerak')
      return errors
    },
    clientNameErrors() {
      const errors = []
      if (!this.$v.clientName.$dirty) return errors
      !this.$v.clientName.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    passErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength &&
        errors.push("Password 8 tadan ko'p bo'lishi kerak characters long")
      !this.$v.password.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    regionErrors() {
      const errors = []
      if (!this.$v.region.$dirty) return errors
      !this.$v.region.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    ...mapGetters(['allStatusName', 'getallregions']),
    returnRegions() {
      let data = []
      this.$store.getters.getallregions.forEach((item) => {
        if (item.id == this.oblast.id) {
          data = item.oblast_region
        }
      })
      return data
    },
    returnDirectorPhoneNumberList() {
      return this.directorPhoneNumberList
    },
    returnStaffPhoneNumberList() {
      return this.staffPhoneNumberList
    },
    // returnPositions() {
    //   return this.$store.state.requests.positions.results;
    // },
    returnStaffDetailsList() {
      return this.staffDeatailLists
    },
    returnBankDetailsList() {
      return this.provider_bank_account
    },
    returnOwnership() {
      return this.$store.state.requests.ownership
    }
  },
  methods: {
    ...mapActions([
      'getClientStatus',
      'getRegions',
      'getOwnership',
      'getMfoBankByNumber'
    ]),

    isNumberKey,

    getMfoDebounce(val) {
      this.getMfoBankByNumber(val)
    },

    // addDirectorPhoneNumberList() {
    //   if (this.directorPhoneNumber !== "" && this.directorFIO !== "") {
    //     this.directorPhoneNumberList.push(this.directorPhoneNumber);
    //     this.directorPhoneNumber = "";
    //   }
    // },
    // addStaffPhoneNumberList() {
    //   if (this.staffPhoneNumber !== "") {
    //     this.staffPhoneNumberList.push(this.staffPhoneNumber);
    //     this.staffPhoneNumber = "";
    //   }
    // },
    // removeDirectorPhoneNumberList(value) {
    //   this.directorPhoneNumberList.splice(value, 1);
    // },
    // removeStaffPhoneNumberList(value) {
    //   this.staffPhoneNumberList.splice(value, 1);
    // },
    // removeStaffDetails(index) {
    //   this.staffDeatailLists.splice(index, 1);
    // },
    // removeBankDetails(index) {
    //   this.provider_bank_account.splice(index, 1);
    // },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: '',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        // if (this.directorPhoneNumber !== "" && this.directorFIO !== "") {
        //   this.directorPhoneNumberList.push(this.directorPhoneNumber);
        // }
        // this.addBank();
        // this.addStaff();
        const data = {
          user_data: {
            username: this.username,
            password: this.password,
            groups: 4
          },
          ownership: this.ownership,
          adress_oblast: this.oblast.id,
          adress_region: this.region.id,
          code_nds: this.ndsNum,
          passport_number: this.passportNum,
          juridical_type: this.selected_opt,
          full_name: this.clientName,
          inn: this.innNum.replace(/\s/g, ''),
          oked: this.okedNum,
          yur_address: this.yurAddress,
          status: this.status,
          comments: this.comments,
          pochta_address: this.emailaddress,
          director_name: this.directorFIO,
          director_phone1: this.directorPhoneNumber1,
          director_phone2: this.directorPhoneNumber2,
          contact_personal_name: this.staffName,
          contact_personal_phone1: this.staffPhoneNumber1,
          contact_personal_phone2: this.staffPhoneNumber2,
          bank_code1: parseInt(this.mfo),
          contact_personal_position: this.staffposition,
          bank_account1: this.account
        }
        if (this.directorPhoneNumber1 !== '') {
          data.director_phone1 = this.code + ' ' + this.directorPhoneNumber1
        }
        if (this.directorPhoneNumber2 !== '') {
          data.director_phone2 = this.code + ' ' + this.directorPhoneNumber2
        }
        if (this.staffPhoneNumber1 !== '') {
          data.contact_personal_phone1 =
            this.code + ' ' + this.staffPhoneNumber1
        }
        if (this.staffPhoneNumber2 !== '') {
          data.contact_personal_phone2 =
            this.code + ' ' + this.staffPhoneNumber2
        }
        // if (this.yur == true) {
        //   data.director_name = this.directorFIO;
        //   data.contact_personal_position = this.staffposition;
        //   data.contact_personal_name = this.staffName;
        // }
        // console.log(data);
        this.newProviderLoading = true
        this.$store
          .dispatch('createProvider', data)
          .then(() => {
            this.newProviderLoading = false
          })
          .catch((err) => {
            this.newProviderLoading = false
            console.error(err)
          })
      }
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Postavshik registratsiya' }
    ])
    this.getClientStatus()
    this.getRegions()
    this.$store.dispatch('getPositions')
  }
}
</script>
<style>
.error {
  color: red;
  display: none;
}
.form-group--error {
  display: block;
}
</style>
